import { withUseGateObserver } from '../gate';
import { useNav, useStore } from '../hooks';
import { gate } from '../service';
import { CandidatePage } from './CandidatePage';
import { CandidatesPage } from './CandidatesPage';
import { FacultyMainPage } from './FacultyMainPage';
import { ErrorPage } from './ErrorPage';
import { GoodbyePage } from './GoodbyePage';
import { LoadingPage } from './LoadingPage';
import { NotFoundPage } from './NotFoundPage';
import { ProgramPage } from './ProgramPage';
import { ProgramsPage } from './ProgramsPage';
import { SignInPage } from './SignInPage';

const useGateObserver = withUseGateObserver(gate);

export const Router: React.FC = () => {
  const gateStatus = useGateObserver('Router');
  const storeStatus = useStore('Router');
  const { route, sub, setRedirect } = useNav();

  if (storeStatus.anyError) {
    return <ErrorPage error={storeStatus.anyError} />;
  }

  if (!gateStatus.ready) {
    return <LoadingPage />;
  }

  if (route === '' || route === 'faculty') {
    if (gateStatus.hasAccess) {
      return <FacultyMainPage />;
    } else {
      setRedirect({ route, sub });
      return <SignInPage />;
    }
  }

  if (route === 'candidates') {
    if (gateStatus.hasAccess) {
      return <CandidatesPage />;
    } else {
      setRedirect({ route, sub });
      return <SignInPage />;
    }
  }

  if (route === 'candidate') {
    if (gateStatus.hasAccess) {
      return <CandidatePage itemId={sub} />;
    } else {
      setRedirect({ route, sub });
      return <SignInPage />;
    }
  }

  if (route === 'programs') {
    if (gateStatus.hasAccess) {
      return <ProgramsPage />;
    } else {
      setRedirect({ route, sub });
      return <SignInPage />;
    }
  }

  if (route === 'program') {
    if (gateStatus.hasAccess) {
      return <ProgramPage itemId={sub} />;
    } else {
      setRedirect({ route, sub });
      return <SignInPage />;
    }
  }

  if (route === 'goodbye') {
    return <GoodbyePage />;
  }

  if (route === 'signin') {
    if (gateStatus.hasAccess) {
      return <FacultyMainPage />;
    }
    return <SignInPage />;
  }

  // waiting federation login
  if (
    window.location.hash === '#_=_' ||
    gateStatus.waitApple ||
    gateStatus.waitFacebook ||
    gateStatus.waitGoogle
  ) {
    return <LoadingPage />;
  }

  // not found
  return <NotFoundPage />;
};
