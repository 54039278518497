import { objKeys, isEmailValid } from '@cpmech/util';
import { ISignUpValues, ISignUpErrors } from '.';
import { t } from '../locale';

// tslint:disable-next-line: max-line-length
// To check a password between 8 to 30 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character
// ref: https://www.w3resource.com/javascript/form/password-validation.php
const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/;

export const isPasswordValid = (pwd: string): boolean => re.test(pwd);

export const signUpValues2errors = (
  values: ISignUpValues,
  ignore?: { [key in keyof Partial<ISignUpErrors>]: boolean },
  simplePassword = false,
) => {
  const errors: ISignUpErrors = {
    email: isEmailValid(values.email) ? '' : t('errorEmail'),
    password: simplePassword
      ? values.password.length >= 8
        ? ''
        : t('errorPasswordSimple')
      : isPasswordValid(values.password)
        ? ''
        : t('errorPassword'),
    code: values.code ? '' : t('errorCode'),
  };
  if (ignore) {
    objKeys(ignore).forEach((key) => (errors[key] = ''));
  }
  const hasError = !!errors.email || !!errors.password || !!errors.code;
  return {
    errors,
    hasError,
  };
};
