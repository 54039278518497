import { ICandidate } from "./candidate";
import { ITextractBlock } from "./textract";

export interface IOcrOutput {
  candidate: ICandidate,
  textract: ITextractBlock[],
}

export interface IOcrDownloadUrl {
  transcriptUrl: string;
  textractUrl: string;
}

export interface IOcrUrlInputQue {
  transcript: string;
}

export interface IOcrAfterUploadInputMut {
  itemId: string; // Candidate's itemId
  transcript: string;
}

export interface IOcrQueries {
  xGetOcrUploadUrl: (input: IOcrUrlInputQue) => Promise<string | null>;
  xGetOcrDownloadUrl: (input: IOcrUrlInputQue) => Promise<IOcrDownloadUrl | null>;
}

export interface IOcrMutations {
  xOcrComputeAfterUpload: (input: IOcrAfterUploadInputMut) => Promise<IOcrOutput | null>;
}

export const refOcrInputQue: IOcrUrlInputQue = {
  transcript: '',
};

export const refOcrInputMut: IOcrAfterUploadInputMut = {
  itemId: '',
  transcript: '',
};

export const refOcrDownloadUrl: IOcrDownloadUrl = {
  transcriptUrl: '',
  textractUrl: '',
};
