import { useState, useEffect } from 'react';
import { store } from '../service';

interface IUseStoreState {
  anyError: string;
  loadCandidateInProgress: boolean;
  loadCandidatesInProgress: boolean;
  ocrAnalysisInProgress: boolean;
  ocrSearchInProgress: boolean;
  loadProgramInProgress: boolean;
  loadProgramsInProgress: boolean;
}

const makeUseStoreState = (): IUseStoreState => ({
  anyError: store.getFirstError(),
  loadCandidateInProgress: store.actions.loadCandidate.inProgress,
  loadCandidatesInProgress: store.actions.loadCandidates.inProgress,
  ocrAnalysisInProgress: store.actions.ocrAnalysis.inProgress,
  ocrSearchInProgress: store.actions.ocrSearch.inProgress,
  loadProgramInProgress: store.actions.loadProgram.inProgress,
  loadProgramsInProgress: store.actions.loadPrograms.inProgress,
});

export const useStore = (observerName: string) => {
  const [data, setData] = useState<IUseStoreState>({
    anyError: '',
    loadCandidateInProgress: false,
    loadCandidatesInProgress: false,
    ocrAnalysisInProgress: false,
    ocrSearchInProgress: false,
    loadProgramInProgress: false,
    loadProgramsInProgress: false,
  });

  useEffect(() => {
    // flag to prevent calling setData when the component is unmounted
    let finished = false;

    // must set the state right here and right now because the login/signup
    // may have been already configured and we missed the notification
    setData(makeUseStoreState());

    // now we can listen to further notifications, if any
    const unsubscribe = store.subscribe(() => {
      if (!finished) {
        setData(makeUseStoreState());
      }
    }, observerName);

    // return clean-up function
    return () => {
      finished = true;
      unsubscribe();
    };

    // note dependencies
  }, [observerName]);

  return data;
};
