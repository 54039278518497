/** @jsxImportSource @emotion/react */
import { useRef } from 'react';
import { RcButton } from '../rcomps';
import { store } from '../service';

const buttonProps = {
  outline: true,
  borderRadius: '200px',
};

interface IButtonUploadTranscriptProps {
  itemId: string;
  validateBeforeUpload: () => boolean;
}

export const ButtonUploadTranscript: React.FC<IButtonUploadTranscriptProps> = ({ itemId, validateBeforeUpload }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      await store.uploadCandidateTranscript(itemId, file);
      // clear state now; otherwise we cannot upload again
      if (fileInputRef.current?.value) {
        fileInputRef.current.value = '';
      }
    }
  };

  const doUpload = () => {
    if (fileInputRef.current) {
      if (validateBeforeUpload()) {
        fileInputRef.current.click();
      }
    }
  };

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={onChange}
        style={{ display: 'none' }}
        accept="image/jpeg, image/jpg, image/png"
      />
      <RcButton
        onClick={doUpload}
        {...buttonProps}
      >
        UPLOAD TRANSCRIPT AND PERFORM OCR
      </RcButton>
    </div>
  );
};
