/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { RcButton, RcInput, RcPair } from '../rcomps';
import { store } from '../service';
import { useNav } from '../hooks';

const fontSize = '18px';

const height = '50px';

const padTop = `padding-top: calc((${fontSize} * 0.8) / 2);`;

const inputProps = {
  fontSize,
  flatRight: true,
  height,
};

const buttonProps = {
  outline: true,
  height,
  borderRadius: '200px',
  color: 'green',
};

interface IFormValues {
  fullName: string;
}

type IFormErrors = IFormValues;

const formValues2errors = (values: IFormValues) => {
  const errors: IFormErrors = { fullName: '' };
  let hasError = false;
  if (values.fullName.length < 3) {
    errors.fullName = 'Full name must have at least three characters';
    hasError = true;
  }
  return { errors, hasError };
};

export interface FormAddCandidateProps {
}

export const FormAddCandidate: React.FC<FormAddCandidateProps> = () => {
  const { goto } = useNav();
  const [formValues, setFormValues] = useState<IFormValues>({ fullName: '' });
  const [formErrors, setFormErrors] = useState<IFormErrors>({ fullName: '' });
  const [touchedButtons, setTouchedButtons] = useState(false); // used to check errors only after clicking buttons

  const validateForm = (): boolean => {
    const res = formValues2errors(formValues);
    setFormErrors(res.errors);
    return !res.hasError;
  };

  const setFullName = (name: string) => {
    const newValues = { ...formValues };
    newValues.fullName = name;
    setFormValues(newValues);
    if (touchedButtons) {
      const res = formValues2errors(newValues);
      setFormErrors({ ...formErrors, ...res });
    }
  };

  const onSave = async () => {
    setTouchedButtons(true);
    if (validateForm()) {
      await store.addCandidate(formValues.fullName, (itemId: string) => goto({ route: 'candidate', sub: itemId }));
    }
  };

  return (
    <div css={css`padding-bottom: 20px;`}>
      <RcPair
        cssLeft='width: 100%;'
        left={
          <RcInput
            name='fullName'
            label='Full Name'
            value={formValues.fullName}
            onChange={(e) => setFullName(e.target.value)}
            error={formErrors.fullName}
            {...inputProps}
          />
        }
        cssRight={padTop}
        right={<RcButton onClick={onSave} flatLeft={true} {...buttonProps}>ADD NEW CANDIDATE</RcButton>}
      />
    </div>
  );
}
