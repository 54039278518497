import axios from 'axios';
import lunr from 'lunr';
import { v4 as uuidv4 } from 'uuid';
import { Iany } from '@cpmech/basic';
import { checkType, checkTypes } from '@cpmech/js2ts';
import { FileExt, name2fileExt, fileExt2contentType } from '@cpmech/util';
import { SimpleStore } from '@cpmech/simple-state';
import {
  refCandidate,
  optCandidate,
  ICandidate,
  IProgram,
  refProgram,
  IProgramTopic,
  ITextractBlock,
  refOcrDownloadUrl,
} from '../data';
import { OcrData } from '../util';
import { config } from './config';
import { gate } from './gate';

type Action =
  | 'loadProgram'
  | 'loadPrograms'
  | 'loadCandidate'
  | 'loadCandidates'
  | 'ocrAnalysis'
  | 'ocrSearch';

const actionNames: Action[] = [
  'loadProgram',
  'loadPrograms',
  'loadCandidate',
  'loadCandidates',
  'ocrAnalysis',
  'ocrSearch',
];

// define the state interface
interface IState {
  program: IProgram | null;
  programs: IProgram[];
  candidate: ICandidate | null;
  candidates: ICandidate[];
  ocrData: OcrData | null;
}

// define a function to generate a blank state
const newZeroState = (): IState => ({
  program: null,
  programs: [],
  candidate: null,
  candidates: [],
  ocrData: null,
});

// extend the SimpleStore class; it may have any additional members
class Store extends SimpleStore<Action, IState, null> {
  constructor(private wss: WebSocket | null = null, private searchIndex: lunr.Index | null = null) {
    super(actionNames, newZeroState);
  }

  // setters //////////////////////////////////////////////////////////////////////////

  // PROGRAM -------------------------------------------------------------------------------------------------

  loadPrograms = async () => {
    this.updateState('loadPrograms', async () => {
      const { data } = await this.callApi({
        query: 'xGetPrograms',
      });
      const programs = checkTypes(refProgram, data);
      if (!programs) {
        throw new Error('Cannot load list of Programs from server. Undefined value.');
      }
      this.state.programs = programs;
    });
  };

  loadProgram = async (itemId: string) => {
    this.updateState('loadProgram', async () => {
      const { data } = await this.callApi({
        query: 'xGetProgram',
        input: { itemId },
      });
      const program = checkType(refProgram, data);
      if (!program) {
        throw new Error('Cannot load Program from server. Undefined value.');
      }
      this.state.program = program;
    });
  };

  deleteProgram = async (itemId: string) => {
    this.updateState('loadPrograms', async () => {
      const { data } = await this.callApi({
        mutation: 'xDelProgram',
        input: { itemId },
      });
      const programs = checkTypes(refProgram, data);
      if (!programs) {
        throw new Error('Delete failed with invalid list of Programs.');
      }
      this.state.programs = programs;
    });
  };

  addProgram = async (name: string, topics: IProgramTopic[], after: (itemId: string) => void) => {
    this.updateState('loadProgram', async () => {
      const { data } = await this.callApi({
        mutation: 'xNewProgram',
        input: { name, topics },
      });
      const program = checkType(refProgram, data);
      if (!program) {
        throw new Error('Cannot add Program. Undefined value.');
      }
      this.state.program = program;
      after(this.state.program.itemId);
    });
  };

  updateProgram = async (itemId: string, name: string, topics: IProgramTopic[]) => {
    this.updateState('loadProgram', async () => {
      const { data } = await this.callApi({
        mutation: 'xSetProgram',
        input: { itemId, name, topics },
      });
      const program = checkType(refProgram, data);
      if (!program) {
        throw new Error('Cannot load Program from server. Undefined value.');
      }
      this.state.program = program;
    });
  };

  // CANDIDATE -----------------------------------------------------------------------------------------------

  loadCandidates = async () => {
    this.updateState('loadCandidates', async () => {
      const { data } = await this.callApi({
        query: 'xGetCandidates',
      });
      const candidates = checkTypes(refCandidate, data, optCandidate);
      if (!candidates) {
        throw new Error('Cannot load list of Candidates from server. Undefined value.');
      }
      this.state.candidates = candidates;
    });
  };

  private processOcrData = async (candidate: ICandidate) => {
    // handle ocr results
    if (!!candidate.textracted && !!candidate.transcript) {
      // get download url
      const res = await this.callApi({
        query: 'xGetOcrDownloadUrl',
        input: { transcript: candidate.transcript },
      })
      const urls = checkType(refOcrDownloadUrl, res.data);
      if (!urls) {
        throw new Error('Cannot load transcript and OCR results');
      }
      const { transcriptUrl, textractUrl } = urls;
      // load image
      const resImg = await axios.get(transcriptUrl, { responseType: 'arraybuffer' });
      const imgBase64 = Buffer.from(resImg.data, 'binary').toString('base64');
      const image = new Image();
      image.src = 'data:image;base64,' + imgBase64;
      // load json
      const resJson = await axios.get(textractUrl);
      const blocks = resJson.data as ITextractBlock[];
      // find program
      const program = this.state.programs.find((p) => p.indexSK === candidate.uqProgram);
      // process OCR data
      store.state.ocrData = new OcrData(blocks, image, program);
    }
  }

  loadCandidate = async (itemId: string) => {
    this.updateState('loadCandidate', async () => {
      // load candidate
      const { data } = await this.callApi({
        query: 'xGetCandidate',
        input: { itemId },
      });
      const candidate = checkType(refCandidate, data, optCandidate);
      if (!candidate) {
        throw new Error('Cannot load Candidate from server. Undefined value.');
      }
      this.state.candidate = candidate;

      // load programs (because we need the list of programs to update data)
      const resPrograms = await this.callApi({
        query: 'xGetPrograms',
      });
      const programs = checkTypes(refProgram, resPrograms.data);
      if (!programs) {
        throw new Error('Cannot load list of Programs from server');
      }
      this.state.programs = programs;
      await this.processOcrData(this.state.candidate);
    });
  };

  deleteCandidate = async (itemId: string) => {
    this.updateState('loadCandidates', async () => {
      const { data } = await this.callApi({
        mutation: 'xDelCandidate',
        input: { itemId },
      });
      const candidates = checkTypes(refCandidate, data, optCandidate);
      if (!candidates) {
        throw new Error('Delete failed with invalid list of Candidates.');
      }
      this.state.candidates = candidates;
    });
  };

  addCandidate = async (fullName: string, after: (itemId: string) => void) => {
    this.updateState('loadCandidate', async () => {
      const { data } = await this.callApi({
        mutation: 'xNewCandidate',
        input: { fullName },
      });
      const candidate = checkType(refCandidate, data, optCandidate);
      if (!candidate) {
        throw new Error('Cannot add Candidate. Undefined value.');
      }
      this.state.candidate = candidate;
      after(this.state.candidate.itemId);
    });
  };

  updateCandidate = async (itemId: string, fullName: string, uqProgram: string) => {
    this.updateState('loadCandidate', async () => {
      const programHasChanged = !(this.state.candidate?.uqProgram === uqProgram);
      const { data } = await this.callApi({
        mutation: 'xSetCandidate',
        input: { itemId, fullName, uqProgram },
      });
      const candidate = checkType(refCandidate, data);
      if (!candidate) {
        throw new Error('Cannot load Candidate from server. Undefined value.');
      }
      this.state.candidate = candidate;
      if (programHasChanged) {
        await this.processOcrData(this.state.candidate);
      }
    });
  };

  uploadCandidateTranscript = async (itemId: string, transcript: File) => {
    this.updateState('loadCandidate', async () => {
      // check
      if (!transcript) {
        throw new Error('A file must be provided');
      }
      if (transcript.name === '') {
        throw new Error('The file name cannot be empty');
      }

      // file key and content type
      let fileExt: FileExt;
      try {
        fileExt = name2fileExt(transcript.name);
      } catch (error) {
        throw new Error('The file must have an extension');
      }
      const filekey = `${uuidv4()}.${fileExt}`;
      const contentType = fileExt2contentType[fileExt];

      // get signed upload url
      const { data } = await this.callApi({
        query: 'xGetOcrUploadUrl',
        input: { transcript: `${filekey}` },
      });

      // perform the upload
      try {
        await axios.put(data, transcript, {
          headers: { 'Content-Type': contentType },
        });
      } catch (error) {
        console.log(error);
        throw new Error('Upload failed due to an unknown reason');
      }

      // perform OCR
      const res = await this.callApi({
        mutation: 'xOcrComputeAfterUpload',
        input: { itemId, transcript: filekey },
      });
      const { candidate, textract } = res.data;

      // image
      const image = new Image();
      image.src = URL.createObjectURL(transcript);

      // update state
      this.state.candidate = candidate as ICandidate;
      const program = this.state.programs.find((p) => p.indexSK === candidate.uqProgram);
      this.state.ocrData = new OcrData(textract as ITextractBlock[], image, program);
    })
  }

  ocrReAnalyze = async (threshold: number) => {
    this.updateState('ocrAnalysis', async () => {
      if (this.state.ocrData) {
        this.state.ocrData.analyze(threshold);
      }
    })
  }

  ocrSearch = async (target: string, threshold: number) => {
    this.updateState('ocrSearch', async () => {
      if (this.state.ocrData) {
        this.state.ocrData.search(target, threshold);
      }
    })
  }

  // private //////////////////////////////////////////////////////////////////////////

  private callApi = async (queryOrMutation: Iany): Promise<any> => {
    const options = config.isLocalApi
      ? { headers: { 'x-data-x': `${gate.user.username},${gate.user.email},customers` } }
      : { headers: { Authorization: `Bearer ${gate.user.idToken}` } };
    return await axios.post(`${config.apiUrl}graphql`, queryOrMutation, options);
  };

}

// instantiate store
export const store = new Store();
