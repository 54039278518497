/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { IconStar } from '@cpmech/iricons/IconStar';
import { RcLinkOrDiv, RcPair } from '../rcomps';
import { useNav } from '../hooks';
import { styles } from '../styles';

export const FacultyMainPage: React.FC = () => {
  const { goto } = useNav();

  const renderLink = (route: string, text: string) => <div css={css`margin: 10px;`}>
    <RcLinkOrDiv
      onClick={() => goto({ route })}
      color={styles.colors.blue()}
      hoverColor={styles.colors.blue(50)}
    >
      <RcPair
        left={<IconStar size={styles.dims.icon.medium} />}
        right={text}
        cssRight="line-height: 1.5em; font-size: 130%;"
        spacing="20px"
      />
    </RcLinkOrDiv>
  </div>;

  return (
    <div
      css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-top: 40px;
        `}
    >
      <h1>EAIT Faculty - Administrative</h1>
      <div css={css`font-size: 100px; padding: 20px;`}>🏢</div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: baseline;
        `}
      >
        {renderLink('candidates', 'List of Candidates')}
        {renderLink('programs', 'List of UQ Programs')}
      </div>
    </div>
  );
};
