/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { RcButton } from '../../rcomps';

export const ButtonScrollToTop: React.FC = () => {
  return (
    <div css={css`display: flex; flex-direction: row; justify-content: right; padding-top: 20px;`}>
      <RcButton
        borderRadius='0'
        onClick={() => window.scrollTo(0, 0)}
      >
        SCROLL TO TOP
      </RcButton>
    </div>
  )
};
