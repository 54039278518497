/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { RcButton, RcInput, RcPair } from '../../rcomps';
import { FUSE_MIN_CHARS, OcrData } from '../../util';
import { FormThreshold } from './FormThreshold';
import { store } from '../../service';
import { useStore } from '../../hooks';

const fontSize = '16px';

const height = '40px';

const padTop = `padding-top: calc((${fontSize} * 0.8) / 2);`;

const inputProps = {
  fontSize,
  flatRight: true,
  height,
};

const buttonProps = {
  outline: true,
  height,
  width: '150px',
  borderRadius: '200px',
};

interface IFormValues {
  target: string,
  matches: string[],
}

interface IFormErrors {
  target: string,
}

const formValues2errors = (values: IFormValues) => {
  const errors: IFormErrors = { target: '' };
  let hasError = false;
  if (values.target.length < FUSE_MIN_CHARS) {
    errors.target = `Search string must have at least ${FUSE_MIN_CHARS} characters`;
    hasError = true;
  }
  return { errors, hasError };
};

export interface FormOcrSearchProps {
  ocrData: OcrData;
}

export const FormOcrSearch: React.FC<FormOcrSearchProps> = ({ ocrData }) => {
  const { ocrSearchInProgress } = useStore('FormOcrSearch');
  const [formValues, setFormValues] = useState<IFormValues>({ target: '', matches: [] });
  const [formErrors, setFormErrors] = useState<IFormErrors>({ target: '' });
  const [touchedButtons, setTouchedButtons] = useState(false); // used to check errors only after clicking buttons

  const validateForm = (): boolean => {
    const res = formValues2errors(formValues);
    setFormErrors(res.errors);
    return !res.hasError;
  };

  const setFormValue = <K extends keyof IFormValues>(key: K, value: string) => {
    const newValues = { ...formValues, [key]: value.trimStart() };
    setFormValues(newValues);
    if (touchedButtons) {
      const res = formValues2errors(newValues);
      setFormErrors({ ...formErrors, [key]: (res.errors as any)[key] });
    }
  };

  const onSearch = async () => {
    setTouchedButtons(true);
    if (validateForm()) {
      await store.ocrSearch(formValues.target, ocrData.thresholdSearch);
    }
  };

  const onRecompute = async (threshold: number) => {
    if (formValues.target.length >= FUSE_MIN_CHARS) {
      await store.ocrSearch(formValues.target, threshold);
    }
  };

  return (
    <div css={css`padding-bottom: 20px;`}>
      <FormThreshold threshold={ocrData.thresholdAnalysis} callback={onRecompute} />
      <RcPair
        cssLeft='width: 100%;'
        left={
          <RcInput
            name='target'
            label='Expression'
            value={formValues.target}
            onChange={(e) => setFormValue('target', e.target.value)}
            error={formErrors.target}
            onEnterKeyUp={onSearch}
            {...inputProps}
          />
        }
        cssRight={padTop}
        right={
          <RcButton
            onClick={onSearch}
            flatLeft={true}
            disabled={ocrSearchInProgress}
            {...buttonProps}>
            SEARCH
          </RcButton>
        }
      />
    </div>
  );
}
