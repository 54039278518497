/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { RcButton, RcInput, RcPair } from '../rcomps';
import { IProgram, IProgramTopic } from '../data';
import { store } from '../service';
import { styles } from '../styles';

const fontSize = '16px';

const height = '40px';

const padTop = `padding-top: calc((${fontSize} * 0.8) / 2);`;

const inputProps = {
  fontSize,
  flatRight: true,
  height,
};

const buttonProps = {
  outline: true,
  height,
  borderRadius: '200px',
};

const buttonAddProps = {
  ...buttonProps,
  color: 'green',
};

const buttonDelProps = {
  ...buttonProps,
  flatLeft: true,
  color: styles.colors.purple(50),
};

const buttonSaveProps = {
  ...buttonProps,
};

interface IFormValues {
  name: string,
  topics: IProgramTopic[],
}

type IFormErrors = IFormValues;

const newZeroErrors = (topics: IProgramTopic[]): IFormErrors => {
  return {
    name: '',
    topics: topics.map((t) => ({
      title: '',
      courses: t.courses.map(() => '')
    }))
  };
}

const formValues2errors = (values: IFormValues) => {
  const errors = newZeroErrors(values.topics);
  let hasError = false;
  if (values.name.length < 3) {
    errors.name = 'Name of program must have at least three characters';
    hasError = true;
  }
  for (let i = 0; i < values.topics.length; i++) {
    if (values.topics[i].title.length < 3) {
      errors.topics[i].title = 'Title must have at least three characters';
      hasError = true;
    }
    for (let j = 0; j < values.topics[i].courses.length; j++) {
      if (values.topics[i].courses[j].length < 3) {
        errors.topics[i].courses[j] = 'Course name must have at least three characters';
        hasError = true;
      }
    }
  }
  return { errors, hasError };
};

export interface FormEditProgramProps {
  program: IProgram;
}

export const FormEditProgram: React.FC<FormEditProgramProps> = ({ program }) => {
  const [formValues, setFormValues] = useState<IFormValues>({ name: program.indexSK, topics: program.topics });
  const [formErrors, setFormErrors] = useState<IFormErrors>(newZeroErrors(program.topics));
  const [touchedButtons, setTouchedButtons] = useState(false); // used to check errors only after clicking buttons

  const validateForm = (): boolean => {
    const res = formValues2errors(formValues);
    setFormErrors(res.errors);
    return !res.hasError;
  };

  const setName = (name: string) => {
    const newValues = { ...formValues };
    newValues.name = name;
    setFormValues(newValues);
    if (touchedButtons) {
      const res = formValues2errors(newValues);
      setFormErrors({ ...formErrors, ...res });
    }
  };

  const setTitle = (topicIndex: number, title: string) => {
    const newValues = { ...formValues };
    newValues.topics[topicIndex].title = title;
    setFormValues(newValues);
    if (touchedButtons) {
      const res = formValues2errors(newValues);
      setFormErrors({ ...formErrors, ...res });
    }
  };

  const setCourse = (topicIndex: number, courseIndex: number, text: string) => {
    const newValues = { ...formValues };
    newValues.topics[topicIndex].courses[courseIndex] = text;
    setFormValues(newValues);
    if (touchedButtons) {
      const res = formValues2errors(newValues);
      setFormErrors({ ...formErrors, ...res });
    }
  };

  const onSave = async () => {
    setTouchedButtons(true);
    if (validateForm()) {
      await store.updateProgram(program.itemId, formValues.name, formValues.topics);
    }
  };

  const onDelTopic = async (topicIndex: number) => {
    const newValues: IFormValues = { ...formValues };
    newValues.topics = newValues.topics.filter((_, i) => i !== topicIndex);
    const newErrors = newZeroErrors(newValues.topics);
    setFormValues(newValues);
    setFormErrors(newErrors);
  };

  const onDelCourse = async (topicIndex: number, courseIndex: number) => {
    const newValues: IFormValues = { ...formValues };
    newValues.topics[topicIndex].courses = newValues.topics[topicIndex].courses.filter((_, j) => j !== courseIndex);
    const newErrors = newZeroErrors(newValues.topics);
    setFormValues(newValues);
    setFormErrors(newErrors);
  };

  const onAddTopic = async () => {
    const newValues = {
      ...formValues,
      topics: [...formValues.topics, {
        title: '',
        courses: [],
      }],
    };
    const newErrors = newZeroErrors(newValues.topics);
    setFormValues(newValues);
    setFormErrors(newErrors);
  };

  const onAddCourse = async (topicIndex: number) => {
    const newValues = { ...formValues };
    newValues.topics[topicIndex].courses.push('');
    const newErrors = newZeroErrors(newValues.topics);
    setFormValues(newValues);
    setFormErrors(newErrors);
  };

  return (
    <div>
      <div css={css`padding-bottom: 20px;`}>
        <RcPair
          cssLeft='width: 100%;'
          left={
            <RcInput
              name='name'
              label='Name'
              value={formValues.name}
              onChange={(e) => setName(e.target.value)}
              error={formErrors.name}
              {...inputProps}
            />
          }
          cssRight={padTop}
          right={<RcButton onClick={onSave} flatLeft={true} {...buttonSaveProps}>SAVE CHANGES</RcButton>}
        />
      </div>
      <div css={css`display:flex; flex-direction: row; justify-content: right;`}>
        <div css={css`width: 90%;`}>
          {formValues.topics.map((t, i) => <div key={i} css={css`padding-bottom: 20px;`}>
            <RcPair
              cssLeft='width: 100%;'
              left={
                <RcInput
                  name='title'
                  label='Title'
                  value={t.title}
                  onChange={(e) => setTitle(i, e.target.value)}
                  error={formErrors.topics[i].title}
                  {...inputProps}
                />
              }
              cssRight={padTop}
              right={<RcButton onClick={() => onDelTopic(i)} {...buttonDelProps}>DELETE</RcButton>}
            />
            <div css={css`display:flex; flex-direction: row; justify-content: right; padding-top: 10px;`}>
              <div css={css`width: 90%;`}>
                {t.courses.map((c, j) => <div key={j} css={css`padding-bottom: 10px;`}>{
                  <RcPair
                    cssLeft='width: 100%;'
                    left={
                      <RcInput
                        name='course'
                        label='Course'
                        value={c}
                        onChange={(e) => setCourse(i, j, e.target.value)}
                        error={formErrors.topics[i].courses[j]}
                        {...inputProps}
                      />
                    }
                    cssRight={padTop}
                    right={<RcButton onClick={() => onDelCourse(i, j)} {...buttonDelProps}>DELETE</RcButton>}
                  />
                }</div>)}
              </div>
            </div>
            <div css={css`display:flex; flex-direction: row; justify-content: right; padding-top: 5px`}>
              <RcButton onClick={() => onAddCourse(i)} {...buttonAddProps}>ADD COURSE</RcButton>
            </div>
          </div>)}
        </div>
      </div>
      <div css={css`display:flex; flex-direction: row; justify-content: right; padding-top: 5px;`}>
        <RcButton onClick={onAddTopic} {...buttonAddProps}>ADD TOPIC</RcButton>
      </div>
      <div css={css`display: flex; flex-direction: row; justify-content: right; padding-top: 40px;`}>
        <RcButton onClick={onSave} {...buttonSaveProps}>SAVE CHANGES</RcButton>
      </div>
    </div>
  );
}