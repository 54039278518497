/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { RcButton, RcInputNumber, RcPair } from '../../rcomps';

const fontSize = '16px';

const height = '40px';

const padTop = `padding-top: calc((${fontSize} * 0.8) / 2);`;

const inputProps = {
  fontSize,
  flatRight: true,
  height,
};

const buttonProps = {
  outline: true,
  height,
  width: '150px',
  borderRadius: '200px',
};

interface IFormValues {
  threshold: string,
}

type IFormErrors = IFormValues;

const formValues2errors = (values: IFormValues) => {
  const errors: IFormErrors = { threshold: '' };
  let hasError = false;
  if (values.threshold === '') {
    errors.threshold = 'The threshold must not be empty';
    hasError = true;
  }
  const n = Number(values.threshold);
  if (n < 0 || n > 1) {
    errors.threshold = 'The threshold must be ≥ 0 and ≤ 1';
    hasError = true;
  }
  return { errors, hasError };
};

export interface FormThresholdProps {
  threshold: number;
  callback: (threshold: number) => void;
}

export const FormThreshold: React.FC<FormThresholdProps> = ({ threshold, callback }) => {
  const [formValues, setFormValues] = useState<IFormValues>({ threshold: `${threshold}` });
  const [formErrors, setFormErrors] = useState<IFormErrors>({ threshold: '' });
  const [touchedButtons, setTouchedButtons] = useState(false); // used to check errors only after clicking buttons

  const validateForm = (): boolean => {
    const res = formValues2errors(formValues);
    setFormErrors(res.errors);
    return !res.hasError;
  };

  const setFormValue = <K extends keyof IFormValues>(key: K, value: string) => {
    const newValues = { ...formValues, [key]: value.trimStart() };
    setFormValues(newValues);
    if (touchedButtons) {
      const res = formValues2errors(newValues);
      setFormErrors({ ...formErrors, [key]: (res.errors as any)[key] });
    }
  };

  const onAction = () => {
    setTouchedButtons(true);
    if (validateForm()) {
      callback(Number(formValues.threshold));
    }
  };

  return (
    <div css={css`padding-bottom: 10px;`}>
      <RcPair
        cssLeft='width: 100%;'
        left={
          <RcInputNumber
            label='Error threshold (≥ 0 and ≤ 1)'
            value={formValues.threshold}
            onChange={(v) => setFormValue('threshold', v)}
            onEnterKeyUp={onAction}
            error={formErrors.threshold}
            {...inputProps}
          />
        }
        cssRight={padTop}
        right={<RcButton onClick={onAction} flatLeft={true} {...buttonProps}>RECOMPUTE</RcButton>}
      />
    </div>
  );
}
