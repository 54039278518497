/** @jsxImportSource @emotion/react */
import { OcrData, fitCanvasToParent } from '../../util';
import { useCallbackRef, useStore } from '../../hooks';
import { FormOcrSearch } from './FormOcrSearch';

export interface IViewTranscriptProps {
  ocrData: OcrData;
}

export const ViewTranscript: React.FC<IViewTranscriptProps> = ({ ocrData }) => {
  const { ocrAnalysisInProgress, ocrSearchInProgress } = useStore('ViewTranscript');

  // this is used to draw the image and overlay rectangles around the found words
  const setRefCanvas = useCallbackRef<HTMLCanvasElement>(
    (canvas) => {
      fitCanvasToParent(canvas);
      const ctx = canvas.getContext('2d');
      if (ctx) {
        const { blocks, image } = ocrData;
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);
        for (let i = 0; i < ocrData.highlight.length; i++) {
          const block = blocks[ocrData.highlight[i]];
          if (block.Geometry && block.Geometry.Polygon) {
            ctx.lineWidth = 3;
            ctx.strokeStyle = 'rgba(255,0,0,0.8)';
            ctx.beginPath();
            block.Geometry.Polygon.forEach(({ X, Y }) => {
              const x = X || 0;
              const y = Y || 0;
              ctx.lineTo(image.width * x, image.height * y);
            });
            ctx.closePath();
            ctx.stroke();
            ctx.lineWidth = 3;
            ctx.strokeStyle = 'rgba(255,199,45,0.8)';
            ctx.beginPath();
            block.Geometry.Polygon.forEach(({ X, Y }) => {
              const x = X || 0;
              const y = Y || 0;
              ctx.lineTo(image.width * x + 2, image.height * y + 2);
            });
            ctx.closePath();
            ctx.stroke();
          }
        }
      }
    },
    () => { },
    [ocrAnalysisInProgress, ocrSearchInProgress],
  );

  return (
    <div>
      <FormOcrSearch ocrData={ocrData} />
      <div>
        <div><b>Highlighting:</b></div>
        <div>
          {(ocrAnalysisInProgress || ocrSearchInProgress) ? (
            <div>... recomputing ...</div>
          ) : (
            <div>
              <ul>
                {ocrData.highlight.map((i, j) => <li key={`${i}-${j}`}>{ocrData.blocks[i].Text || ''}</li>)}
              </ul>
            </div>
          )}
        </div>
      </div>
      <canvas ref={setRefCanvas} />
    </div>
  )
};
