import Fuse from 'fuse.js';
import { IProgram, ITextractBlock } from "../data";

const MIN_BLOCK_LINE_CHARS = 4;
export const FUSE_MIN_CHARS = 3;
const FUSE_DEFAULT_THRESHOLD = 0.2; // 0 means an exact match

export interface ITextractFuseData {
  blockIndex: number;
  text: string;
}

export type ITextractFuse = Fuse<ITextractFuseData>;

export type IAnalysisResultsEntry = { [courseName in string]: string[] };

export type IAnalysisResults = { [topicTitle in string]: IAnalysisResultsEntry };

export class OcrData {
  public blocks: ITextractBlock[];
  public analysis: IAnalysisResults;
  public highlight: number[];
  public thresholdAnalysis: number;
  public thresholdSearch: number;
  private searchable: ITextractFuseData[];
  private fuseAnalysis: ITextractFuse;
  private fuseSearch: ITextractFuse;

  // Allocates a new instance
  constructor(blocks: ITextractBlock[], public image: HTMLImageElement, public program?: IProgram) {
    // filter blocks
    this.blocks = [];
    for (let i = 0; i < blocks.length; i++) {
      const b = blocks[i];
      if (b.BlockType === 'LINE') {
        if (b.Text && b.Confidence && b.Text.length > MIN_BLOCK_LINE_CHARS) {
          this.blocks.push(b);
        }
      }
    }

    // build array for Fuse
    this.searchable = this.blocks.map((b, i) => ({ blockIndex: i, text: b.Text || '' }));

    // build search indices
    this.thresholdAnalysis = FUSE_DEFAULT_THRESHOLD;
    this.thresholdSearch = FUSE_DEFAULT_THRESHOLD;
    this.fuseAnalysis = this._rebuildAnalysisIndex(FUSE_DEFAULT_THRESHOLD);
    this.fuseSearch = this._rebuildSearchIndex(FUSE_DEFAULT_THRESHOLD);

    // perform analysis
    this.analysis = {};
    this.highlight = [];
    this.analyze(this.thresholdAnalysis);
  }

  // Compares the OCR data against a UQ program
  analyze(threshold: number) {
    if (threshold !== this.thresholdAnalysis) {
      this._rebuildAnalysisIndex(threshold);
    }
    this.analysis = {};
    if (this.program) {
      for (let topic of this.program.topics) {
        this.analysis[topic.title] = {};
        for (let course of topic.courses) {
          const res = this.fuseAnalysis.search(course);
          if (res.length > 0) {
            this.analysis[topic.title][course] = res.map((r) => r.item.text);
            this.highlight.push(res[0].refIndex);
          }
        }
      }
    }
  }

  // Saves the block indices to "highlight"
  search(target: string, threshold: number) {
    if (threshold !== this.thresholdSearch) {
      this._rebuildSearchIndex(threshold);
    }
    try {
      let res = this.fuseSearch.search(target);
      this.highlight = res.map((v) => Number(v.refIndex));
    } catch (err: any) {
      console.log(err.message);
      this.highlight = [];
    }
  }

  // Rebuilds the analysis index
  private _rebuildAnalysisIndex(threshold: number) {
    this.thresholdAnalysis = threshold;
    this.fuseAnalysis = new Fuse(this.searchable, {
      keys: ['text'],
      minMatchCharLength: FUSE_MIN_CHARS,
      threshold: this.thresholdAnalysis,
      includeScore: true,
      ignoreLocation: true,
    });
    return this.fuseAnalysis;
  }

  // Rebuilds the search index
  private _rebuildSearchIndex(threshold: number) {
    this.thresholdSearch = threshold;
    this.fuseSearch = new Fuse(this.searchable, {
      keys: ['text'],
      minMatchCharLength: FUSE_MIN_CHARS,
      threshold: this.thresholdSearch,
      includeScore: true,
      ignoreLocation: true,
    });
    return this.fuseSearch;
  }
}
