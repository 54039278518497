/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { RcButton, RcInput, RcPair, RcPicker, RcPickerEntry } from '../rcomps';
import { ICandidate, IProgram } from '../data';
import { store } from '../service';
import { ButtonUploadTranscript } from './ButtonUploadTranscript';

const cssMessage = css`color: red;`;

const fontSize = '16px';

const height = '40px';

const padTop = `padding-top: calc((${fontSize} * 0.8) / 2);`;

const inputProps = {
  fontSize,
  flatRight: true,
  height,
};

const buttonProps = {
  outline: true,
  height,
  borderRadius: '200px',
};

const buttonSaveProps = {
  ...buttonProps,
};

interface IFormValues {
  fullName: string,
  uqProgram: string,
}

interface IFormErrors {
  fullName: string,
}

const formValues2errors = (values: IFormValues) => {
  const errors: IFormErrors = { fullName: '' };
  let hasError = false;
  if (values.fullName.length < 3) {
    errors.fullName = 'Full name must have at least three characters';
    hasError = true;
  }
  return { errors, hasError };
};

export interface FormEditCandidateProps {
  programs: IProgram[];
  candidate: ICandidate;
}

export const FormEditCandidate: React.FC<FormEditCandidateProps> = ({ programs, candidate }) => {
  const [formValues, setFormValues] = useState<IFormValues>({
    fullName: candidate.fullName || '',
    uqProgram: candidate.uqProgram || ''
  });
  const [formErrors, setFormErrors] = useState<IFormErrors>({ fullName: '' });
  const [touchedButtons, setTouchedButtons] = useState(false); // used to check errors only after clicking buttons

  const validateForm = (): boolean => {
    const res = formValues2errors(formValues);
    setFormErrors(res.errors);
    return !res.hasError;
  };

  const setFormValue = <K extends keyof IFormValues>(key: K, value: string) => {
    const newValues = { ...formValues, [key]: value.trimStart() };
    setFormValues(newValues);
    if (touchedButtons) {
      const res = formValues2errors(newValues);
      setFormErrors({ ...formErrors, [key]: (res.errors as any)[key] });
    }
  };

  const onSave = async () => {
    setTouchedButtons(true);
    if (validateForm()) {
      await store.updateCandidate(candidate.itemId, formValues.fullName, formValues.uqProgram);
    }
  };

  const noPendingChanges = (
    formValues.fullName === candidate.fullName &&
    formValues.uqProgram === candidate.uqProgram
  );

  const validateBeforeUpload = () => {
    setTouchedButtons(true)
    if (validateForm() && noPendingChanges) {
      return true;
    } else {
      return false;
    }
  };

  const pickerEntries: RcPickerEntry[] = programs.map((p, i) => ({
    message: p.indexSK, // indexSK = name
    onClick: () => setFormValue('uqProgram', p.indexSK),
  }));

  return (
    <div>
      <div css={css`padding-bottom: 10px;`}>
        <RcPair
          cssLeft='width: 100%;'
          left={
            <RcInput
              name='fullName'
              label='Full Name'
              value={formValues.fullName}
              onChange={(e) => setFormValue('fullName', e.target.value)}
              error={formErrors.fullName}
              {...inputProps}
            />
          }
          cssRight={padTop}
          right={<RcButton onClick={onSave} flatLeft={true} {...buttonSaveProps}>SAVE CHANGES</RcButton>}
        />
      </div>
      <div css={css`padding-bottom: 20px;`}>
        <RcPair
          cssLeft='width: 100%;'
          left={
            <RcPicker
              name='uqProgram'
              selected={candidate.uqProgram}
              entries={pickerEntries}
              {...inputProps}
            />
          }
          cssRight={padTop}
          right={<RcButton onClick={onSave} flatLeft={true} {...buttonSaveProps}>SAVE CHANGES</RcButton>}
        />
      </div>
      <div css={css`display: flex; flex-direction: row; justify-content: right; padding-top: 5px;`}>
        {noPendingChanges ? (
          <ButtonUploadTranscript itemId={candidate.itemId} validateBeforeUpload={validateBeforeUpload} />
        ) : (
          formValues.uqProgram === '' ?
            <div css={cssMessage}>... program is empty ...</div> :
            <div css={cssMessage}>... pending changes ...</div>
        )}
      </div>
    </div>
  );
}
