/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  gateParams,
  GateSignUpForm,
  GateStore,
  LocalGateSignUpForm,
  LocalGateStore,
} from '../gate';
import { config, gate } from '../service';
import { styles } from '../styles';

gateParams.loading.colorTitle = styles.colors.grey();
gateParams.loading.colorSpinner = styles.colors.grey();

export const SignInPage: React.FC = () => {
  const signInForm = config.liveGate ? (
    <GateSignUpForm
      gate={gate as GateStore}
      mayHideEmailLogin={false}
      initShownEmailLogin={false}
      withFederated={false}
    />
  ) : (
    <LocalGateSignUpForm gate={gate as LocalGateStore} ignoreErrors={true} />
  );

  return (
    <div
      css={css`
        background-color: #ffffff;
        margin: 60px 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
      `}
    >
      {signInForm}
    </div>
  );
};
