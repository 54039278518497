/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { withUseGateObserver } from '../gate';
import {
  RcButton,
  rcDefaultMenuOptions,
  RcLinkOrDiv,
  RcMenuEntry,
  RcMenuHoriz,
} from '../rcomps';
import { styles } from '../styles';
import { gate } from '../service';
import { useNav } from '../hooks';

const buttonProps = {
  borderRadius: styles.dims.input.borderRadius,
  height: styles.dims.input.height,
  outline: true,
  color: styles.colors.white(),
  hoverColor: styles.colors.grey(50),
  hoverColorOutline: 'rgba(0,0,0,0.3)',
};

const useGateObserver = withUseGateObserver(gate);

export interface HeaderProps {
  withMenuButton?: boolean;
}

export const Header: React.FC<HeaderProps> = () => {
  const gateStatus = useGateObserver('Header');
  const { goto, gohome } = useNav();

  const menuEntries: RcMenuEntry[] = [];

  menuEntries.push({
    comp: (
      <RcLinkOrDiv
        onClick={gohome}
        color={styles.colors.white()}
        hoverColor={styles.colors.blue(50)}
      >
        <img src="/fig/uq-logo--reversed.png" alt="logo" />
      </RcLinkOrDiv>
    ),
  });

  if (gateStatus.hasAccess) {
    menuEntries.push({
      comp: (
        <RcButton
          onClick={() => {
            gate.signOut();
            goto({ route: 'goodbye' });
          }}
          {...buttonProps}
        >
          SIGN OUT
        </RcButton>
      ),
    });
  } else {
    menuEntries.push({
      comp: (
        <RcButton onClick={() => goto({ route: 'signin' })} {...buttonProps}>
          ACCESS
        </RcButton>
      ),
    });
  }

  return (
    <div
      css={css`
        background: #51247a;
      `}
    >
      <RcMenuHoriz
        entries={menuEntries}
        height={styles.dims.header.height}
        marginTop={'0px'}
        paddingVert={'0px'}
        options={rcDefaultMenuOptions({
          colorLabel: styles.colors.white(),
          paddingHoriz: '20px',
          maxWidth: `${styles.dims.minMaxPageWidth}px`,
        })}
      />
    </div>
  );
};
