/** @jsxImportSource @emotion/react */
import { OcrData } from '../../util';
import { RcFlexTable, RcFlexTableEntry } from '../../rcomps';
import { ButtonScrollToTop } from '../auxiliary';

export interface IOcrResultsTableProps {
  ocrData: OcrData;
}

export const OcrResultsTable: React.FC<IOcrResultsTableProps> = ({ ocrData }) => {
  const tableData: RcFlexTableEntry[] = ocrData.blocks.map((b) => ({
    confidence: Math.round((b.Confidence || 0) * 100) / 100,
    text: b.Text,
  }));

  return (
    <div>
      <RcFlexTable rows={tableData}
        mainColumn='confidence'
        hideMainLabelWide={false}
        labels={{ confidence: 'Confidence', text: 'Sentence' }}
        aligns={{ confidence: 'center', text: 'left' }}
        proportions={[2, 8]}
        colorMainWide='#eee'
      />
      <ButtonScrollToTop />
    </div>
  )
};
