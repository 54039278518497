/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { IconArrowBack } from '@cpmech/iricons/IconArrowBack';
import { useEffect } from 'react';
import { store } from '../service';
import { styles } from '../styles';
import { useNav, useStore } from '../hooks';
import { LoadingPage } from './LoadingPage';
import { RcPairLinkOrDiv } from '../rcomps';
import {
  AnalysisOfCriteria,
  FormEditCandidate,
  OcrResultsTable,
  ViewTranscript,
} from '../components';

export interface CandidatePageProps {
  itemId: string; // candidate item id
}

export const CandidatePage: React.FC<CandidatePageProps> = ({ itemId }) => {
  const { goto } = useNav();
  const { loadCandidateInProgress } = useStore('CandidatePage');

  useEffect(() => {
    store.loadCandidate(itemId);
  }, [itemId]);

  if (loadCandidateInProgress) {
    return <LoadingPage />;
  }

  if (!store.state.candidate) {
    return <div>Candidate's Details are not Available</div>;
  }

  const candidate = store.state.candidate;

  const renderGoBack = () => <div>
    <RcPairLinkOrDiv onClick={() => goto({ route: 'candidates' })}
      left={<IconArrowBack />}
      right="Return to list of candidates"
      cssExtraLeft='padding-top:5px'
    />
  </div>;

  return (
    <div css={styles.article.root}>
      {renderGoBack()}
      <div css={css`
          display:flex;
          flex-direction: column;
          gap: 15px;
        `}
      >
        <h2>Candidate</h2>
        <FormEditCandidate programs={store.state.programs} candidate={candidate} />
      </div>
      {candidate.textracted && store.state.ocrData && (
        <div>
          <h2>Analysis</h2>
          <AnalysisOfCriteria ocrData={store.state.ocrData} />
          <h2>Transcript</h2>
          <ViewTranscript ocrData={store.state.ocrData} />
          <h2>OCR Results</h2>
          <OcrResultsTable ocrData={store.state.ocrData} />
        </div>
      )}
      <div css={css`padding-top:20px;`}>
        {renderGoBack()}
      </div>
    </div >
  );
};
