/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useNav, useStore } from '../hooks';
import { RcModalYesNo, RcCard, RcButton, RcPairLinkOrDiv } from '../rcomps';
import { store } from '../service';
import { styles } from '../styles';
import { LoadingPage } from './LoadingPage';
import { FormAddProgram } from '../components';
import { IconArrowBack } from '@cpmech/iricons/IconArrowBack';

const buttonDelProps = {
  outline: true,
  color: 'red',
  borderRadius: '200px',
  width: '120px',
  flatRight: true,
};

const buttonEditProps = {
  outline: true,
  borderRadius: '200px',
  width: '120px',
  flatLeft: true,
};

export interface ProgramsPageProps {
}

export const ProgramsPage: React.FC<ProgramsPageProps> = () => {
  const { goto } = useNav();
  const { loadProgramInProgress, loadProgramsInProgress } = useStore('ProgramsPage');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingItemId, setDeletingItemId] = useState('');

  useEffect(() => {
    store.loadPrograms();
  }, []);

  if (loadProgramInProgress || loadProgramsInProgress) {
    return <LoadingPage />;
  }

  const doDeleteProgram = async () => {
    if (!!deletingItemId) {
      await store.deleteProgram(deletingItemId);
    }
  };

  const renderProgram = (i: number) => store.state.programs[i].topics.map((t, j) => <div key={j}>
    <div css={css`color: ${styles.colors.purple()}`}>{t.title}</div>
    <ul>
      {t.courses.map((c, k) => <li key={k}> {c} </li>)}
    </ul>
  </div>);

  const renderList = () => store.state.programs.map((p, i) =>
    <div key={i} css={css`padding-bottom: 20px;`}>
      <RcCard title={p.indexSK.toUpperCase()} initShow={true}>
        <div css={css`padding: 10px 20px;`}>
          {renderProgram(i)}
          <div css={css`
            display:flex;
            flex-direction: row;
            justify-content: right;
            gap:0px;
          `}>
            <RcButton
              onClick={() => {
                setDeletingItemId(p.itemId);
                setShowDeleteModal(true);
              }}
              {...buttonDelProps}
            >
              DELETE
            </RcButton>
            <RcButton
              onClick={() => goto({ route: 'program', sub: p.itemId })}
              {...buttonEditProps}
            >
              EDIT
            </RcButton>
          </div>
        </div>
      </RcCard>
    </div>);

  const renderConfirmationModal = () => <RcModalYesNo
    onClose={() => setShowDeleteModal(false)}
    onYes={() => doDeleteProgram()}
    onNo={() => setShowDeleteModal(false)}
    leftButtonStyle={{ borderRadius: '200px', width: '100px' }}
    rightButtonStyle={{ borderRadius: '200px', width: '100px' }}
  >
    <div>
      <div css={css`padding-bottom: 20px`}>Continue with this program deletion?</div>
      <div css={css`padding-bottom: 20px; display: flex; justify-content: center; font-weight: bold;`}>
        {store.state.programs.find((c) => c.itemId === deletingItemId)?.indexSK || ''}
      </div>
    </div>
  </RcModalYesNo>;

  const renderGoBack = () => <div>
    <RcPairLinkOrDiv onClick={() => goto({ route: 'faculty' })}
      left={<IconArrowBack />}
      right="Return to main page"
      cssExtraLeft='padding-top:5px'
    />
  </div >;

  return (
    <div css={styles.article.root}>
      {renderGoBack()}
      {showDeleteModal && renderConfirmationModal()}
      <h1>Add New Program</h1>
      <FormAddProgram />
      <h1>UQ Programs</h1>
      {renderList()}
      <div css={css`padding-top:20px;`}>
        {renderGoBack()}
      </div>
    </div>
  );
};
