export interface IProgramTopic {
  title: string;
  courses: string[];
}

export interface IProgram {
  itemId: string;
  aspect: 'PROGRAM';
  indexSK: string; // name, such as 'Chemical Engineering', 'Civil Engineering'
  topics: IProgramTopic[];
}

export interface IProgramInputQue {
  itemId: string;
}

export interface IProgramInputMutNew {
  name: string; // == indexSK; name, such as 'Chemical Engineering', 'Civil Engineering'
  topics: IProgramTopic[];
}

export interface IProgramInputMutDel {
  itemId: string;
}

export interface IProgramInputMutSet {
  itemId: string;
  name: string;
  topics: IProgramTopic[];
}

export interface IProgramQueries {
  xGetProgram: (input: IProgramInputQue) => Promise<IProgram | null>;
  xGetPrograms: () => Promise<IProgram[]>;
}

export interface IProgramMutations {
  xNewProgram: (input: IProgramInputMutNew) => Promise<IProgram | null>;
  xSetProgram: (input: IProgramInputMutSet) => Promise<IProgram | null>;
  xDelProgram: (input: IProgramInputMutDel) => Promise<IProgram[]>;
}

export const refProgramTopic: IProgramTopic = {
  title: '',
  courses: [''],
}

export const newZeroProgram = (): IProgram => ({
  itemId: '',
  aspect: 'PROGRAM',
  indexSK: '',
  topics: [refProgramTopic],
});

export const refProgram: IProgram = {
  itemId: '',
  aspect: 'PROGRAM',
  indexSK: '',
  topics: [refProgramTopic],
};

export const refProgramInputQue: IProgramInputQue = {
  itemId: '',
};

export const refProgramInputMutNew: IProgramInputMutNew = {
  name: '',
  topics: [refProgramTopic],
};

export const refProgramInputMutDel: IProgramInputMutDel = {
  itemId: '',
};

export const refProgramInputMutSet: IProgramInputMutSet = {
  itemId: '',
  name: '',
  topics: [refProgramTopic],
};
