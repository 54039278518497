/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { IconArrowBack } from '@cpmech/iricons/IconArrowBack';
import { FormAddCandidate } from '../components';
import { useNav, useStore } from '../hooks';
import {
  RcButton,
  RcFlexTable,
  RcFlexTableLabels,
  RcModalYesNo,
  RcPairLinkOrDiv,
} from '../rcomps';
import { LoadingPage } from './LoadingPage';
import { store } from '../service';
import { styles } from '../styles';

const buttonDelProps = {
  outline: true,
  color: 'red',
  borderRadius: '200px',
};

const labels: RcFlexTableLabels = {
  fullName: 'Full Name',
  uqProgram: 'UQ Program',
  transcript: 'Transcript',
  textracted: 'OCR',
  actions: 'Actions',
}

const proportions = [10, 1, 1];

export interface CandidatesPageProps {
}

export const CandidatesPage: React.FC<CandidatesPageProps> = () => {
  const { goto } = useNav();
  const { loadCandidatesInProgress } = useStore('CivilEngPage');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingItemId, setDeletingItemId] = useState('');

  useEffect(() => {
    store.loadCandidates();
  }, []);

  if (loadCandidatesInProgress) {
    return <LoadingPage />;
  }

  const doDeleteCandidate = async () => {
    if (!!deletingItemId) {
      await store.deleteCandidate(deletingItemId);
    }
  };

  const tableEntries = store.state.candidates.map((c, i) => ({
    fullName: c.fullName || '',
    uqProgram: c.uqProgram || 'Civil Engineering',
    transcript: c.transcript ? 'YES' : 'NO',
    textracted: c.textracted ? 'YES' : 'NO',
    actions: (
      <div>
        <RcButton
          onClick={() => {
            setDeletingItemId(store.state.candidates[i].itemId);
            setShowDeleteModal(true);
          }}
          {...buttonDelProps}>
          DELETE
        </RcButton>
      </div>
    )
  }));

  const renderConfirmationModal = () => <RcModalYesNo
    onClose={() => setShowDeleteModal(false)}
    onYes={() => doDeleteCandidate()}
    onNo={() => setShowDeleteModal(false)}
    leftButtonStyle={{ borderRadius: '200px', width: '100px' }}
    rightButtonStyle={{ borderRadius: '200px', width: '100px' }}
  >
    <div>
      <div css={css`padding-bottom: 20px`}>Continue with this candidate deletion?</div>
      <div css={css`padding-bottom: 20px; display: flex; justify-content: center; font-weight: bold;`}>
        {store.state.candidates.find((c) => c.itemId === deletingItemId)?.fullName || ''}
      </div>
    </div>
  </RcModalYesNo>;

  const renderGoBack = () => <div>
    <RcPairLinkOrDiv onClick={() => goto({ route: 'faculty' })}
      left={<IconArrowBack />}
      right="Return to main page"
      cssExtraLeft='padding-top:5px'
    />
  </div>;

  return (
    <div css={styles.article.root}>
      {showDeleteModal && renderConfirmationModal()}
      {renderGoBack()}
      <h1>Add new Candidate</h1>
      <FormAddCandidate />
      <h1>List of Candidates</h1>
      <RcFlexTable
        rows={tableEntries}
        hideMainLabelWide={false}
        mainColumn='fullName'
        labels={labels}
        proportions={proportions}
        onEdit={(i) => goto({ route: 'candidate', sub: store.state.candidates[i].itemId })}
        colorMainWide='#eee'
      />
      <div css={css`padding-top:20px;`}>
        {renderGoBack()}
      </div>
    </div >
  );
};
