/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { IconArrowBack } from '@cpmech/iricons/IconArrowBack';
import { useEffect } from 'react';
import { store } from '../service';
import { styles } from '../styles';
import { useNav, useStore } from '../hooks';
import { LoadingPage } from './LoadingPage';
import { RcPairLinkOrDiv } from '../rcomps';
import { FormEditProgram } from '../components';

export interface ProgramPageProps {
  itemId: string; // program item id
}

export const ProgramPage: React.FC<ProgramPageProps> = ({ itemId }) => {
  const { goto } = useNav();
  const { loadProgramInProgress } = useStore('ProgramPage');

  useEffect(() => {
    store.loadProgram(itemId);
  }, [itemId]);

  if (loadProgramInProgress) {
    return <LoadingPage />;
  }

  if (!store.state.program) {
    return <div>Program's Details are not Available</div>;
  }

  const renderGoBack = () => <div>
    <RcPairLinkOrDiv onClick={() => goto({ route: 'programs' })}
      left={<IconArrowBack />}
      right="Return to list of programs"
      cssExtraLeft='padding-top:5px'
    />
  </div>;

  return (
    <div css={styles.article.root}>
      {renderGoBack()}
      <div css={css`
          display:flex;
          flex-direction: column;
          gap: 15px;
        `}
      >
        <h2>Program</h2>
        <FormEditProgram program={store.state.program} />
      </div>
      <div css={css`padding-top:20px;`}>
        {renderGoBack()}
      </div>
    </div >
  );
};
