/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { RcButton, RcInput, RcPair } from '../rcomps';
import { store } from '../service';
import { useNav } from '../hooks';

const fontSize = '18px';

const height = '50px';

const padTop = `padding-top: calc((${fontSize} * 0.8) / 2);`;

const inputProps = {
  fontSize,
  flatRight: true,
  height,
};

const buttonProps = {
  outline: true,
  height,
  borderRadius: '200px',
  color: 'green',
};

interface IFormValues {
  name: string,
}

type IFormErrors = IFormValues;

const formValues2errors = (values: IFormValues) => {
  const errors: IFormErrors = { name: '' };
  let hasError = false;
  if (values.name.length < 3) {
    errors.name = 'Name of program must have at least three characters';
    hasError = true;
  }
  return { errors, hasError };
};

export interface FormAddProgramProps {
}

export const FormAddProgram: React.FC<FormAddProgramProps> = () => {
  const { goto } = useNav();
  const [formValues, setFormValues] = useState<IFormValues>({ name: '' });
  const [formErrors, setFormErrors] = useState<IFormErrors>({ name: '' });
  const [touchedButtons, setTouchedButtons] = useState(false); // used to check errors only after clicking buttons

  const validateForm = (): boolean => {
    const res = formValues2errors(formValues);
    setFormErrors(res.errors);
    return !res.hasError;
  };

  const setName = (name: string) => {
    const newValues = { ...formValues };
    newValues.name = name;
    setFormValues(newValues);
    if (touchedButtons) {
      const res = formValues2errors(newValues);
      setFormErrors({ ...formErrors, ...res });
    }
  };

  const onSave = async () => {
    setTouchedButtons(true);
    if (validateForm()) {
      await store.addProgram(formValues.name, [], (itemId: string) => goto({ route: 'program', sub: itemId }));
    }
  };

  return (
    <div css={css`padding-bottom: 20px;`}>
      <RcPair
        cssLeft='width: 100%;'
        left={
          <RcInput
            name='name'
            label='Name'
            value={formValues.name}
            onChange={(e) => setName(e.target.value)}
            error={formErrors.name}
            {...inputProps}
          />
        }
        cssRight={padTop}
        right={<RcButton onClick={onSave} flatLeft={true} {...buttonProps}>ADD NEW PROGRAM</RcButton>}
      />
    </div>
  );
}
