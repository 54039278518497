/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { OcrData } from '../../util';
import { RcCard, RcFlexTable, RcFlexTableEntry } from '../../rcomps';
import { FormThreshold } from './FormThreshold';
import { useStore } from '../../hooks';
import { store } from '../../service';

const cssCourse = css`
  width: 100%;
  padding-bottom: 10px;
`;

const cssMatches = css`
  padding-left: 20px;
`;

export interface AnalysisOfCriteriaProps {
  ocrData: OcrData;
}

export const AnalysisOfCriteria: React.FC<AnalysisOfCriteriaProps> = ({ ocrData }) => {
  const { ocrAnalysisInProgress } = useStore('AnalysisOfCriteria');

  if (ocrData.program === undefined) {
    return <div>UQ Program is not available</div>;
  }

  const renderCardEntries = (topic: string, course: string, recomputing: boolean) => {
    if (recomputing) {
      return <div css={cssMatches}>... recomputing ...</div>;
    }
    return (
      <div css={cssMatches}>
        {ocrData.analysis[topic][course].map((found, k) => <div key={`${topic}-${course}-${k}`}>
          {found}
        </div>)}
      </div>
    );
  };

  const tableEntries: RcFlexTableEntry[] = Object.keys(ocrData.analysis).map((topic, i) => ({
    topic,
    data: Object.keys(ocrData.analysis[topic]).map((course, j) => <div key={`${i}-${j}`} css={cssCourse}>
      <RcCard
        initShow={false}
        title={course}
        borderRadius='0px'
        headerHeight='40px'
        cssTitle=''
      >
        {renderCardEntries(topic, course, ocrAnalysisInProgress)}
      </RcCard>
    </div>),
  }));

  const onRecompute = async (threshold: number) => {
    await store.ocrReAnalyze(threshold);
  };

  return (
    <div>
      <FormThreshold threshold={ocrData.thresholdAnalysis} callback={onRecompute} />
      <RcFlexTable
        rows={tableEntries}
        hideMainLabelWide={false}
        mainColumn='topic'
        labels={{ topic: 'Topic', data: 'Courses' }}
        colorMainWide='#eee'
        proportions={[1, 3]}
      />
    </div>
  );
};
