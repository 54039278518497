/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { styles } from '../styles';

export const Footer: React.FC = () => {
  return (
    <footer
      css={css`
        color: white;
        background-color: #51247a;
        padding: 60px 40px;
      `}
    >
      <div
        css={css`
          max-width: ${styles.dims.minMaxPageWidth}px;
          margin: 0 auto;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 50px;
          `}
        >
        </div>
      </div>
    </footer>
  );
};
