export interface ICandidate {
  itemId: string;
  aspect: 'CANDIDATE';
  indexSK: string; // createdAt = new Date().toISOString()

  fullName?: string;
  transcript?: string;
  textracted?: boolean;
  uqProgram?: string;
}

export interface ICandidateInputQue {
  itemId: string;
}

export interface ICandidateInputMutNew {
  fullName: string;
}

export interface ICandidateInputMutDel {
  itemId: string;
}

export interface ICandidateInputMutSet {
  itemId: string;
  fullName?: string;
  transcript?: string;
  textracted?: boolean;
  uqProgram?: string;
}

export interface ICandidateQueries {
  xGetCandidate: (input: ICandidateInputQue) => Promise<ICandidate | null>;
  xGetCandidates: () => Promise<ICandidate[]>;
}

export interface ICandidateMutations {
  xNewCandidate: (input: ICandidateInputMutNew) => Promise<ICandidate | null>;
  xSetCandidate: (input: ICandidateInputMutSet) => Promise<ICandidate | null>;
  xDelCandidate: (input: ICandidateInputMutDel) => Promise<ICandidate[]>;
}

export const newZeroCandidate = (): ICandidate => ({
  itemId: '',
  aspect: 'CANDIDATE',
  indexSK: '',

  fullName: '',
  transcript: '',
  textracted: false,
  uqProgram: '',
});

export const refCandidate: ICandidate = {
  itemId: '',
  aspect: 'CANDIDATE',
  indexSK: '',

  fullName: '',
  transcript: '',
  textracted: false,
  uqProgram: '',
};

export const optCandidate = {
  fullName: true,
  transcript: true,
  textracted: true,
  uqProgram: true,
};

export const refCandidateInputQue: ICandidateInputQue = {
  itemId: '',
};

export const refCandidateInputMutNew: ICandidateInputMutNew = {
  fullName: '',
};

export const refCandidateInputMutDel: ICandidateInputMutDel = {
  itemId: '',
};

export const refCandidateInputMutSet: ICandidateInputMutSet = {
  itemId: '',
  fullName: '',
  transcript: '',
  uqProgram: '',
};

export const optCandidateInputMutSet = {
  fullName: true,
  transcript: true,
  uqProgram: true,
};
